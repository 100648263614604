.ql-editor .video-wrapper {
  max-width: 100%;
  margin-bottom: 1em;
}

.ql-editor .video-wrapper iframe {
  max-width: 100%;
}

/* For responsive videos */
.ql-editor .video-wrapper[style*="padding-bottom"] {
  height: 0;
  overflow: hidden;
}

.ql-editor .video-wrapper[style*="padding-bottom"] iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Ensure videos are not too small on smaller screens */
@media (max-width: 640px) {
  .ql-editor .video-wrapper {
    width: 100% !important;
    height: auto !important;
    padding-bottom: 56.25% !important;
    position: relative;
  }

  .ql-editor .video-wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.quill-editor .ql-container {
  font-size: 16px !important;
}

.quill-editor .ql-editor p {
  font-size: 14px !important;
}

.quill-editor .ql-editor h1 {
  font-size: 24px !important;
}

/* Add more specific rules as needed */